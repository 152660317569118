<template>
  <div class="personalInfo">
    <mt-header :title="$t('personalInfo')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="collapse">
      <div :class="['item', { 'active': list.indexOf('personalInfo') > -1 }]">
        <div class="header" @click="onCollapse('personalInfo')">
          <i class="iconfont">&#xe62a;</i>
          {{ $t('personalInfo') }}
        </div>
        <div class="content">
          <mt-cell :title="$t('name')">
            <span>{{ data.name }}</span>
          </mt-cell>
          <mt-cell :title="$t('idcard')">
            <span>{{ data.idcard }}</span>
          </mt-cell>
          <mt-cell :title="$t('phone')">
            <span>{{ data.phone }}</span>
          </mt-cell>
          <mt-cell :title="$t('gender')">
            <span v-if="data.sex == 'male'">{{ $t('male') }}</span>
            <span v-if="data.sex == 'female'">{{ $t('female') }}</span>
          </mt-cell>
          <mt-cell :title="$t('degree')">
            <span>{{ data.education }}</span>
          </mt-cell>
          <mt-cell :title="$t('marriage')">
            <span>{{ data.maritalStatusName }}</span>
          </mt-cell>
          <mt-cell :title="$t('residentialAddress')">
            <span>{{ data.homeAddr }}</span>
          </mt-cell>
        </div>
      </div>
      <div :class="['item', { 'active': list.indexOf('workInfo') > -1 }]">
        <div class="header" @click="onCollapse('workInfo')">
          <i class="iconfont">&#xe62a;</i>
          {{ $t('workInfo') }}
        </div>
        <div class="content">
          <mt-cell :title="$t('companyName')">
            <span>{{ data.company }}</span>
          </mt-cell>
          <mt-cell :title="$t('averageMonthlyIncome')">
            <span>{{ data.monthlyIncome }}</span>
          </mt-cell>
          <mt-cell :title="$t('workPhone')">
            <span>{{ data.companyPhone }}</span>
          </mt-cell>
        </div>
      </div>
      <div :class="['item', { 'active': list.indexOf('wontactInfo') > -1 }]">
        <div class="header" @click="onCollapse('wontactInfo')">
          <i class="iconfont">&#xe62a;</i>
          {{ $t('wontactInfo') }}
        </div>
        <div class="content">
          <mt-cell :title="$t('relativeContact')">
            <span>{{ data.contactRelation }}</span>
          </mt-cell>
          <mt-cell :title="$t('name')">
            <span>{{ data.contactName }}</span>
          </mt-cell>
          <mt-cell :title="$t('phone')">
            <span>{{ data.contactPhone }}</span>
          </mt-cell>
          <mt-cell :title="$t('otherContact')">
            <span>{{ data.otherContactRelation }}</span>
          </mt-cell>
          <mt-cell :title="$t('name')">
            <span>{{ data.otherContactName }}</span>
          </mt-cell>
          <mt-cell :title="$t('phone')">
            <span>{{ data.otherContactPhone }}</span>
          </mt-cell>
        </div>
      </div>
      <div :class="['item', { 'active': list.indexOf('idcardInfo') > -1 }]">
        <div class="header" @click="onCollapse('idcardInfo')">
          <i class="iconfont">&#xe62a;</i>
          {{ $t('idcardInfo') }}
        </div>
        <div class="content">
          <ul>
            <li v-for="(item, index) in (data.images || [])" :key="index">
              <img v-lazy="item.imageUrl">
            </li>
          </ul>
        </div>
      </div>
      <div :class="['item', { 'active': list.indexOf('bankCardInfo') > -1 }]">
        <div class="header" @click="onCollapse('bankCardInfo')">
          <i class="iconfont">&#xe62a;</i>
          {{ $t('bankCardInfo') }}
        </div>
        <div class="content">
          <mt-cell :title="$t('name')">
            <span>{{ data.name }}</span>
          </mt-cell>
          <mt-cell :title="$t('bank')">
            <span>{{ data.bankName }}</span>
          </mt-cell>
          <mt-cell :title="$t('bankAccountNumber')">
            <span>{{ data.bankCardNo | formatBankCardNo }}</span>
          </mt-cell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { getUserInfo, getUserInfoDetail, userBankCard } from '../../utils/api'
export default {
  name: 'PersonalInfo',
  components: {
    callPhone
  },
  data() {
    return {
      list: [],
      data: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getData(),
        this.getdataDetail(),
        this.getUserBankCard(),
      ])
      this.onCollapse('bankCardInfo')
      this.$indicator.close();
    },
    async getData() {
      await this.$axios({
        method: 'get',
        url: getUserInfo
      })
        .then((e) => {
          if (e.status.code == "000") {
            Object.assign(
              this.data,
              this.deleteEmpty(e.body.customerDto),
              this.deleteEmpty(e.body)
            )
          }
        })
        .catch(() => {})
    },
    async getdataDetail() {
      await this.$axios({
        method: 'get',
        url: getUserInfoDetail
      })
        .then((e) => {
          if (e.status.code == "000") {
            Object.assign(
              this.data,
              this.deleteEmpty(e.body.customerDto),
              this.deleteEmpty(e.body)
            )
          }
        })
        .catch(() => {})
    },
    async getUserBankCard() {
      await this.$axios({
        method: 'get',
        url: userBankCard
      })
        .then((e) => {
          if (e.status.code == "000") {
            Object.assign(
              this.data,
              this.deleteEmpty(e.body)
            )
          }
        })
        .catch(() => {})
    },
    onCollapse(val) {
      let index = this.list.indexOf(val);
      if (index > -1) {
        this.list.splice(index, 1)
      } else {
        this.list.push(val)
      }
    },
    deleteEmpty(data) {
      let obj = {}
      for (let i in data) {
        if (data[i]) {
          obj[i] = data[i]
        }
      }
      return obj;
    }
  }
}
</script>

<style lang="scss" scoped>
.personalInfo {
  .collapse {
    margin: 20px 10px;
    background-color: #f7f7f7;
    border-radius: 3px;
    border: 1px solid #dcdee2;
    ul,li {
      padding: 0;
      margin: 0;
      list-style: none;
    }
    .item {
      border-top: 1px solid #dcdee2;
      &:first-child {
        border-top: 0;
      }
      &.active {
        .header {
          border-bottom: 1px solid #dcdee2;
        }
        .content {
          display: block;
        }
      }
      .header {
        height: 38px;
        line-height: 38px;
        padding-left: 16px;
        color: #666;
        cursor: pointer;
        position: relative;
        border-bottom: 1px solid transparent;
        transition: all .2s ease-in-out;
        .iconfont {
          font-size: 12px;
          margin-right: 5px;
        }
      }
      .content {
        display: none;
        color: #515a6e;
        margin-bottom: 10px;
        background-color: #fff;
        /deep/ .mint-cell-title {
          white-space: nowrap;
        }
        ul {
          padding: 20px;
        }
        img {
          max-width: 200px;
          max-height: 200px;
          margin: 5px;
        }
      }
    }
  }
}
</style>